<template>
    <modal 
        ref="modalRef"
        image="/img/gifs/megaphone.gif"
        :title="$ml.get('surveyModal.title')"
        :body="$ml.with('name', getUserName).get('surveyModal.body')"
        :confirm="$ml.get('surveyModal.confirm')" 
        :cancel="$ml.get('surveyModal.cancel')"
        @action="openLink"
    >
    <p></p>
    </modal>
</template>
<script>

import Modal from "@/components/Modal";

export default {
    name: 'Survey',
    components: {Modal},
    computed: {
        getUserName() {
        const registrationData = JSON.parse(localStorage.getItem('registrationData'));
        return registrationData ? registrationData.firstName : null;
    }
    },
    methods: {
        openLink(){
            this.setStorasge(true);
            this.$refs.modalRef.closeModal();
            window.open(this.$ml.get('surveyModal.link'));
        },
        checkModalSurvey(){
            if(this.$ml.get('surveyModal.active')){
                const bunner = JSON.parse(localStorage.getItem('bunner'));
                const showModal = !bunner || !bunner.checked && bunner.date && moment() > moment(bunner.date);
                if (showModal) {
                    setTimeout(() => {
                        this.setStorasge(false, moment().add(14, 'days').valueOf());
                        this.$refs.modalRef.openModal();
                    }, 2000)
                    return true;
                }
            }
        },
        setStorasge(checked, date){
            localStorage.setItem('bunner', JSON.stringify({checked: checked, date: date}));
        }
    }
}
</script>